const ROUTE_EXCLUDES = ["/entrar", "/sair", "/inicio", "/comecar", "/termos-de-uso", "/privacidade"];

export default async function useLoggedInChecker() {
  const { token, initToken } = useToken();
  const isApp = useIsApp();
  const route = useRoute();

  if (isApp) {
    await initToken();
  }

  if (!!token.value && !ROUTE_EXCLUDES.includes(route.path)) {
    await navigateTo("/inicio", { replace: true });
  }
}
